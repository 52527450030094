import { app, blog, website } from '@getpopsure/private-constants';
import { ArrowRightIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { AnnouncementBar } from 'components/AnnoucementBar';
import ImageCard from 'components/imageCard';
import LinkTo from 'components/linkTo';
import ReviewCarousel from 'components/reviewCarousel';
import { getReviews } from 'components/reviewCarousel/content/reviews';
import {
  ENGLISH_LOCALE_IDENTIFIER,
  GERMAN_LOCALE_IDENTIFIER,
} from 'constants/i18n';
import { productLinks } from 'constants/internalLinks';
import images from 'content/homepage/images';
import type {
  TVerticalGroupLookup,
  TVerticalLookup,
} from 'content/policy-links/products';
import { groupedVerticals } from 'content/policy-links/products';
import { VerticalType } from 'models/product';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { BlogPostType } from 'pages/index.page';
import europeanUnionImage from 'public/static/home/security-section/eu.svg';
import gdprImage from 'public/static/home/security-section/gdpr.svg';
import { useRef } from 'react';
import { AnnouncementBarDocument } from 'types.generated';
import { goToSection } from 'util/goToSection';

import DifferentiationCards from './components/DifferentiationCards';
import ReviewBadges from './components/ReviewBadges';
import UniqueSellingPoints from './components/UniqueSellingPoints';
import styles from './styles.module.scss';

const htmlTagRegex = /(<([^>]+)>)/gi;
const apostropheHtmlEntity = '&#8217;';

export const Homepage = ({
  navOffset,
  blogPosts,
  germanPaths,
  announcementBar,
}: {
  blogPosts: BlogPostType[];
  navOffset?: number;
  germanPaths: Array<string>;
  announcementBar?: AnnouncementBarDocument;
}) => {
  const productGridRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const router = useRouter();
  const isLocaleGerman = router.locale === GERMAN_LOCALE_IDENTIFIER;

  const imgLocaleKey = isLocaleGerman
    ? GERMAN_LOCALE_IDENTIFIER
    : ENGLISH_LOCALE_IDENTIFIER;

  const germanProductKeys = germanPaths
    .map((link) =>
      Object.keys(productLinks).find(
        (key) => productLinks[key as VerticalType] === link
      )
    )
    .filter((value) => !!value);

  const getGermanVerticals = (verticalGroups: TVerticalGroupLookup[]) =>
    verticalGroups
      .map((group) => ({
        ...group,
        items: group.items.filter((item) =>
          germanProductKeys.includes(item.key)
        ),
      }))
      .filter((group) => group.items.length > 0); // remove group with no product

  const productGrids = isLocaleGerman
    ? getGermanVerticals(groupedVerticals(t))
    : groupedVerticals(t);

  const reviewContent = getReviews(t);

  return (
    <div className={styles.root}>
      <div className="mt72 bg-grey-100">
        {announcementBar && <AnnouncementBar document={announcementBar} />}
        <section
          className={classNames(
            'mx16 d-flex ai-center jc-center',
            styles.heroSectionWrapper
          )}
        >
          <div
            className={classNames('d-flex ai-center py56', styles.heroSection)}
          >
            <header className={classNames(styles.heroSectionTextWrapper)}>
              <h1 className="p-h1 p-h1--xl p--serif tc-grey-700">
                {t('home:section.1.title', 'Honest, simple insurance.')}
              </h1>
              <p className="p-p mt16 tc-grey-700">
                {t(
                  'home:herosection.description',
                  'All your insurance in one place, without the paperwork.'
                )}
              </p>
              <button
                className="p-btn p-btn--primary wmn3 mt24"
                type="button"
                onClick={() => goToSection(productGridRef, navOffset)}
              >
                {t('home:herosection.action', 'See our policies')}
              </button>
              <ReviewBadges />
            </header>
            <div className={classNames(styles.heroSectionImageWrapper)}>
              <NextImage
                src={images.hero[imgLocaleKey]}
                width={464}
                alt="A woman sat on a couch while holding a mug and looking directly to the camera"
                priority={true}
                style={{ maxWidth: '100%', objectFit: 'contain' }}
              />
            </div>
          </div>
        </section>
      </div>
      <div className={classNames('mt56', styles.uspSection)}>
        <section className="p-body">
          <div
            className={classNames(
              'd-flex ai-start jc-between fd-column',
              styles.uspCard
            )}
          >
            <div className={styles.uspCardText}>
              <div>
                <div className="p-p tc-grey-600">
                  <UniqueSellingPoints />
                </div>
              </div>
            </div>
            <div className={classNames(styles.uspCardImage, 'mt24')}>
              <NextImage
                src={images.uniqueSellingPoints[imgLocaleKey]}
                alt="A woman in front of a kitchen sink holds a mug while looking at a smartphone"
                loading="eager"
                width={420}
                style={{ maxWidth: '100%' }}
              />
            </div>
          </div>
        </section>
      </div>
      <section
        className={classNames('p-body mt56', styles.productSection)}
        ref={productGridRef}
        id="insurances"
      >
        <h2 className="p-h2 p--serif">
          {t('home:productgrid.title', 'Our policies')}
        </h2>
        <div
          data-aos="animate-underline"
          data-aos-once="true"
          className={`mt8 ${styles.separator}`}
        />
        <div>
          {productGrids.map((verticalGroup) => (
            <div key={verticalGroup.key}>
              <header className="d-flex jc-between">
                <h3
                  className={classNames(
                    'd-flex flex-wrap ai-center p-h4 mb16 tc-grey-600',
                    styles.itemHeader
                  )}
                >
                  <NextImage
                    className={classNames('mr8', styles.categoryIcon)}
                    src={verticalGroup.icon}
                    alt={verticalGroup.title}
                  />
                  {verticalGroup.title}
                </h3>
                {!isLocaleGerman && verticalGroup.key === 'health' && (
                  <LinkTo
                    href={`${website.base}/health-insurance`}
                    className={classNames(styles.healthLink)}
                  >
                    <p className={classNames(styles.desktopHealthLink)}>
                      {t(
                        'home:productgrid.healthComparison.link.desktop',
                        'Compare health insurances'
                      )}
                    </p>
                    <p className={classNames(styles.mobileHealthLink)}>
                      {t(
                        'home:productgrid.healthComparison.link.mobile',
                        'Compare them'
                      )}
                    </p>
                    <ArrowRightIcon noMargin size={16} />
                  </LinkTo>
                )}
              </header>
              <div
                className={classNames(
                  styles.productGridItems,
                  'd-grid c-gap16 r-gap16'
                )}
              >
                {verticalGroup.items.map((vertical: TVerticalLookup) => (
                  <LinkTo
                    href={vertical.key ? productLinks[vertical.key] : ''}
                    aria-label={vertical.shortTitle}
                    key={vertical.shortTitle}
                    className={classNames(
                      'bg-grey-100 p24 br8 p-a',
                      styles.verticalItem
                    )}
                  >
                    <div className={styles.verticalItemImage}>
                      <NextImage
                        className="br8"
                        width={72}
                        height={72}
                        src={vertical.icon}
                        alt={vertical.title}
                      />
                    </div>
                    <div className="d-flex fd-column jc-center">
                      <h4 className="p-h4 tc-grey-900">
                        {vertical.shortTitle}
                      </h4>
                      <p className="p-p--small tc-grey-600">
                        {vertical.excerpt}
                      </p>
                    </div>
                  </LinkTo>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div
          className={`${styles.recToolCard} bg-purple-100 p24 mt56 br8 d-flex fd-column`}
        >
          <div className={styles.recToolCardText}>
            <h2 className="p-h2 tc-grey-900">
              {t(
                'home:rectool.cta.title',
                'Not sure? See what you really need'
              )}
            </h2>
            <p className="p-p mt8 tc-grey-700">
              {t(
                'home:rectool.cta.description',
                'Try our free recommendation tool to check which policies are worth considering and which are not.'
              )}
            </p>
            <LinkTo
              href={app.recommendationTool}
              className="mt24 p-btn p-btn--primary"
            >
              {t('home:rectool.cta.button', 'Start assessment')}
            </LinkTo>
          </div>
          <div className={classNames(styles.recToolCardImage)}>
            <NextImage
              src={images.recTool[imgLocaleKey]}
              alt="An illustrated preview of Feather's insurance recommendation tool"
              width="420"
              style={{ maxWidth: '100%', objectFit: 'contain' }}
            />
          </div>
        </div>
      </section>
      <section
        className={classNames(
          'p-body mt56 pb32',
          styles.differentiationSection
        )}
      >
        <h2 className="p-h2 p--serif">
          {t('home:differentiation.title', 'Your insurance companion')}
        </h2>
        <div
          data-aos="animate-underline"
          data-aos-once="true"
          className={`mt8 ${styles.separator}`}
        />
        <DifferentiationCards imgLocaleKey={imgLocaleKey} />
      </section>
      <div className="p-body">
        <ReviewCarousel
          reviewContent={reviewContent}
          isTitleCentered={false}
          displayReviewCount
        />
      </div>
      {!isLocaleGerman && (
        <section className={classNames('mt56', styles.blogSection)}>
          <div className="p-body">
            <div className="d-flex jc-between">
              <div>
                <header>
                  <h2 className="p-h2 p--serif">
                    {t('home:section.blogposts.title', 'Our latest articles')}
                  </h2>
                </header>
                <div
                  data-aos="animate-underline"
                  data-aos-once="true"
                  className={`mt8 ${styles.separator}`}
                />
              </div>
              <a
                className={classNames(
                  'p-btn p-btn--secondary-grey',
                  styles.blogButtonDesktop
                )}
                href={blog.base}
              >
                {t('home:section.blogposts.readmore', 'Read more')}
              </a>
            </div>
            <div
              className={classNames(
                'mt32 d-flex f-wrap gap16',
                styles.sectionNewsContainer
              )}
            >
              {blogPosts &&
                blogPosts.length &&
                blogPosts.map(({ node: item }) => (
                  <a
                    href={item.link}
                    key={item.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ImageCard
                      className={classNames('wmx4', styles.newsCard)}
                      title={item.title.replace(htmlTagRegex, '')}
                      description={item.excerpt
                        .replace(htmlTagRegex, '')
                        .replace(apostropheHtmlEntity, "'")}
                      img={{
                        src: item.featuredImage?.node.sourceUrl || '',
                        alt: item.featuredImage?.node.altText || '',
                      }}
                      width={288}
                      height={124}
                    />
                  </a>
                ))}
            </div>
            <div className="ta-center">
              <a
                className={classNames(
                  'p-btn p-btn--secondary-grey mt32',
                  styles.blogButtonMobile
                )}
                href={blog.base}
              >
                {t('home:section.blogposts.readmore', 'Read more')}
              </a>
            </div>
          </div>
        </section>
      )}
      <section
        className={`${styles.dataSecuritySection} p-body d-flex jc-center f-wrap mt56 gap24`}
      >
        <div
          className={classNames(
            'd-inline-flex fd-column bg-grey-100 br12 p24',
            styles.securityCard
          )}
        >
          <div className="d-flex ai-start">
            <NextImage
              width={32}
              src={europeanUnionImage}
              alt="european union logo"
            />
            <div className="ml24">
              <h3 className="p-h3 tc-grey-900">
                {t(
                  'home:section.security.serversineu.title',
                  'Servers in the EU'
                )}
              </h3>
              <p className="p-p--small mt8 tc-grey-600">
                {t(
                  'home:section.security.serversineu.description',
                  'User data is processed in our app and isn&#8217;t being accessed by third parties.'
                )}
              </p>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'd-inline-flex fd-column bg-grey-100 br12 p24',
            styles.securityCard
          )}
        >
          <div className="d-flex ai-start">
            <NextImage width={32} src={gdprImage} alt="GDPR" />
            <div className="ml24">
              <h3 className="p-h3 tc-grey-900">
                {t(
                  'home:section.security.gdprcompliant.title',
                  'GDPR compliant'
                )}
              </h3>
              <p className="p-p--small mt8 tc-grey-600">
                {t(
                  'home:section.security.gdprcompliant.description',
                  'Feather only collects and stores necessary data according to Europe&#8217;s data privacy laws.'
                )}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
